<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-if="searchStatus" status="my-orange" @click="searchStatus = false">隐藏查询</vxe-button>
        <vxe-button v-if="!searchStatus" status="my-orange" icon="fa fa-search" @click="searchStatus = true">查询</vxe-button>
        <vxe-button v-permission="'NeighborhoodTopicsAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="searchStatus" class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)" @reset="handleSearch(true, true)">
        <vxe-form-item title="状态" span="8">
          <vxe-select v-model="listQuery.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="(item, index) in statusArray"
              :key="index"
              :label="item"
              :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <CustomList ref="CustomList" :columns="tableColumns" :list-query="listQuery" @getList="getList">
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'NeighborhoodTopicsEditButton'" size="small" type="text" @click="handleEdit(row)">编辑</el-button>
        <el-button v-permission="'NeighborhoodTopicsDeleteButton'" size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="handleSearch()" />
  </div>
</template>

<script>
import CustomList from '@/components/CustomList/index'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import { mapActions } from 'vuex'
import Form from './components/form'
export default {
  name: 'Topics',
  components: { Form, CustomList, ...CustomTemplate },
  data() {
    return {
      searchStatus: false,
      statusArray: ['下线', '正常'],
      listQuery: {},
      tableColumns: [
        {
          prop: 'title',
          title: '话题'
        },
        {
          title: '状态',
          formatter: (row, column) => {
            switch (row.status) {
              case 0:
                return '下线'
              case 1:
                return '正常'
              default:
                return ''
            }
          }
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating'
        }
      ]
    }
  },
  methods: {
    ...mapActions(['neighborhoodTopicsPublicPageList', 'neighborhoodTopicsDelete']),
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        this.$set(this, 'listQuery', this.$options.data.call(this).listQuery)
      }
      console.log(this.listQuery, 'this.listQuery')
      this.customListGetList(resetCurrentPage)
    },
    customListGetList(resetCurrentPage = false) {
      this.$refs.CustomList.getList(resetCurrentPage)
    },
    getList(params, callback) {
      this.neighborhoodTopicsPublicPageList(params).then(response => {
        response.data = {
          records: response.data
        }
        callback(response)
      }).catch(() => {})
    },
    handleAdd() {
      this.$refs.Form.handleAdd()
    },
    handleEdit(row) {
      this.$refs.Form.handleEdit(row)
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.neighborhoodTopicsDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.handleSearch(true)
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
