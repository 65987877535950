<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    />
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
export default {
  name: 'Form',
  components: { CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formStatus: '',
      formModel: {
        title: '',
        status: 1
      }
    }
  },
  computed: {
    formItem() {
      return {
        'title': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '话题',
          'maxlength': 100
        },
        'status': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['下线', '正常'],
          'clearable': false
        }
      }
    },
    formRules() {
      return {
        title: [
          { required: true, message: '话题不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['neighborhoodTopicsAdd', 'neighborhoodTopicsUpdate']),
    handleAdd() {
      this.init('add')
    },
    handleEdit(row) {
      this.init('edit', row)
    },
    init(formStatus, row) {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      if (row) {
        this.formId = row.id
        this.formModel.id = row.id
        this.formModel.title = row.title
        this.formModel.status = row.status
      }
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.$refs.CustomDrawer.closeLoading()
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }

          switch (this.formStatus) {
            case 'add':
              this.neighborhoodTopicsAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.neighborhoodTopicsUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
